export default defineNuxtRouteMiddleware(to => {
  if (process.client) {
    const loggedIn = localStorage.getItem('userlogin');
    if (
      loggedIn &&
      (to.fullPath === '/ar/auth/signup' ||
        to.fullPath === '/auth/signup' ||
        to.fullPath === '/auth/login' ||
        to.fullPath === '/ar/auth/login')
    ) {
      return navigateTo('/');
    }
  }
});
